import axios from "axios";
import store from "./store";
import Vue from 'vue';


let HTTP = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin":"*",
		"Accept":"*"
		
	}
});

HTTP.interceptors.request.use(
	function(config) {
		config.headers.authorization = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
		if (config.method != "get" && !store.state.isLoaderShow) {
			store.state.isLoaderShow = true;
		}
		return config;
	},
	function(error) {
		Promise.reject(error);
	}
);

// Add a response interceptor
HTTP.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
	store.state.isLoaderShow = false;
	if (response.config.method != "get") {
	Vue.swal({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 5000,
		icon: "success",
		title: "Success!",
		text: response.data.msg,
	});
}

    return response;
  }, function (error) {
	store.state.isLoaderShow = false;
	switch (error.response.status) {
		case 401: {
			
			localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
			window.location.href = "/";
			break;
		}
		case 422: {
			let data = error.response.data;
			
			for (var k in data) {
				Vue.swal({
					toast: true,
					position: "top-end",
					showConfirmButton: false,
					timer: 5000,
					icon: "success",
					title: "Success!",
					text: error.response.msg,
				});
			}
			break;
		}
		case 403: {
		//	router.replace("/restricted-access");
			break;
		}
	}
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });




export default HTTP;
