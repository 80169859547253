// import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';

// Vue.use(Vuetify);

// export default new Vuetify({
// });


import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
	theme: {
		themes: {
			light: {
				primary: '#4FC3F7',
				secondary: '#b0bec5',
				accent: '#8c9eff',
				error: '#b71c1c',
				bg_theme: '#4FC3F7'

			},
		},
	},
}

export default new Vuetify(opts)
