import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		menuList: [],
		userDetail: {},
		isLoaderShow: false,
		isMobileDevice: false,
	},
	mutations: {
		setMenu(state, data) {
			state.menuList = data;
		},
		setUserDetail(state, data) {
			state.userDetail = data;
		}
	},
	actions: {},
	modules: {}
});
