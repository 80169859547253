import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import axios from "./axios-auth";
import VueAxios from "vue-axios";
import VueRouter from 'vue-router'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import store from "./store/index.js";
import moment from 'moment';
moment.locale('in');


import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2);

import 'sweetalert2/dist/sweetalert2.min.css';

import Paginate from "vuejs-paginate";
Vue.component(Paginate);

import VuetifyConfirm from 'vuetify-confirm'

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Accept',
  buttonFalseText: 'Discard',
  buttonTrueColor: 'primary',
  buttonFalseColor: 'error',
  color: 'primary',
  icon: 'mdi-warning',
  title: 'Warning?',
  width: 350,
  property: '$confirm'
})


Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: en.messages[rule] // assign message
  });
});

import router from './router';

// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'



Vue.config.productionTip = false

Vue.use(VueAxios, axios);

Vue.use(VueRouter);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
