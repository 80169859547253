import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [

		{
			path: '',
			component: () => import('@/components/layouts/Login.vue'),
			children: [
				{
					path: '/', name: 'login', component: () => import('./view/auth/login.vue'),
					meta: {
						page_title: 'Login'
					}
				},
				{
					path: '/register', name: 'register', component: () => import('./view/auth/user-register.vue'),
					meta: {
						page_title: 'Register'
					}
				},
				{
					path: '/forgot-password', name: 'forgot-password', component: () => import('./view/auth/forgot-password.vue'),
					meta: {
						page_title: 'Forgot-Password'
					}
				},
				{
					path: '/reset-password', name: 'reset-password', component: () => import('./view/auth/reset-password.vue'),
					meta: {
						page_title: 'Reset-Password'
					}
				},

			]
		},

		{
			path: '',
			component: () => import('@/components/layouts/Dashboard.vue'),
			children: [
				{
					path: '/dashboard', name: 'dashboard', component: () => import('./view/dashboard/index.vue'),
					meta: {

						breadcrumb: [
							{ title: 'Home', url: '/dashboard' },
							{ title: 'Dashboard', active: true }
						],
						page_title: 'Dashboard'
					}
				},

				{
					path: '/get_find_coverage', name: 'find-service-authorizations-coverage', component: () => import('./view/find-service-authorizations-coverage/find-service-authorizations-coverage.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/dashboard' },
							{ title: 'Find Service Authorizations Coverage', active: true },
						],
						page_title: 'Find Service Authorizations Coverage'
					}
				},

				{
					path: '/set_open_serv_authorizations', name: 'edit-open-sas', component: () => import('./view/edit-open-sas/edit-open-sas.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/dashboard' },
							{ title: 'Edit Open SAs', active: true },
						],
						page_title: 'Edit Open SAs'
					}
				},



				{
					path: '/change-password', name: 'change-passowrd', component: () => import('./view/change-password/index.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/dashboard' },
							{ title: 'Change Password', active: true },
						],
						page_title: 'Change Password'
					}
				},

				{
					path: '/set_assigned_serv_authorizations', name: 'Edit-Assigned-Serv-Authorizations', component: () => import('./view/edit-assigned-serv-authorizations/edit-assigned-serv-authorizations.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/dashboard' },
							{ title: 'Edit Assigned Serv Authorizations', active: true },
						],
						page_title: 'Edit Assigned Serv Authorizations'
					}
				},

				{
					path: '/set_providers', name: 'Edit-Providers-View', component: () => import('./view/edit-providers/edit-providers.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/dashboard' },
							{ title: 'Edit Providers View', active: true },
						],
						page_title: 'Edit Providers View'
					}
				},

				{
					path: '*', component: () => import('./view/error/page-not-found-dashboard.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/dashboard' },
							{ title: 'Page Not Found', active: true },
						],
						page_title: 'Page Not Found'
					}

				},

			]
		},




		// {path:'*',component: () => import('./view/PageNotFound.vue'),}

	]
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.page_title;
	next();
});

export default router;