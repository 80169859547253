<template>
	<v-app dark>
		<v-dialog v-model="$store.state.isLoaderShow" max-width="290">
			<v-card>
				<v-card-text class="text-xs-center">
					<v-progress-circular
						:size="70"
						indeterminate
						class="primary--text"
					/>
				</v-card-text>
			</v-card>
		</v-dialog>
		<router-view />
	</v-app>
</template>

<script>
	export default {
		name: "App",

		components: {},

		data: () => ({
			//
		}),
	};
</script>


